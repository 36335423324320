export default {
    dashboard: "داشبورد",
    exchange: "معامله",
    markets: "بازارها",
    assets: "دارایی‌ها",
    wallet: "کیف پول",
    transactions: "تراکنش ها",
    deposit: "واریز",
    transfer: "انتقال",
    withdraw: "برداشت",
    orders: "سفارشات",
    openOrders: "سفارشات باز",
    history: "تاریخچه",
    ordersHistory: "سفارشات",
    preconditionOrdersHistory: "حد سود/ضرر",
    stopLimitOrders: "حد سود/ضرر",
    guide: "راهنما",
    excoino: "رفتن به صفحه اصلی اکسکوینو",
    excoinoPanel: "بازگشت به اکسکوینو",
    userProfile: "حساب کاربری",
    affiliateProfile: "معرفی به دوستان",
    support: "پشتیبانی",
    logout: "خروج",
    register: "ثبت نام",
    userCenter: "حساب کاربری",
    helpCenter: "پشتیبانی",
    loginRegister: "ورود/ثبت نام",
    staking: "استخر‌های مشارکت",
    activeLanguage: "زبان های فعال در اکسکوینو",
    prices: "لیست ارزها",
    investing: "سرمایه‌گذاری",
    blog: "بلاگ",
    menu: {
        dashborad: "داشبورد",
        wallet: {
            wallet: "کیف پول",
            main: "اصلی",
            mainDetail: "واریز، برداشت",
            spot: "اسپات (مارکت)",
            spotDetail: "کیف پول اسپات",
            transfer: "انتقال",
            transferDetail:"انتقال دارایی بین کیف پول اصلی و اسپات",
            transferToMain:"انتقال به کیف پول اصلی",
            transferToMainDetail:"انتقال دارایی ها",
            transferToSpot:"انتقال به کیف پول اسپات",
            transferToSpotDetail:"انتقال دارایی ها"
        },

        dealing: {
            dealing: "معامله",
            fastConvert: "تبدیل سریع",
            fastConvertDetail: "تبدیل یک ارز به ارز دیگر",
            spot: "اسپات (مارکت)",
            spotDetail: "معامله P2P",
            ordering: "سفارش گذاری",
            orderingDetail: "ثبت سفارش در OTC",
            testMarket: "اسپات آزمایشی (دمو)",
            testMarketDetail: "معامله دمو P2P",
            perfectMoney: "پرفکت مانی",
            perfectMoneyDetail: "خرید و فروش پرفکت مانی",
            withdrawHistory: "تاریخچه برداشت",
            depositHistory: "تاریخچه واریز"
        },

        markets: "بازارها",
        prices: "لیست ارزها",
        orders: {
            orders: "سفارشات",
            openOrders: "سفارشات باز",
            openOrdersDetail: "سفارشات باز شما",
            profitLossLimit: "حد سود و ضرر",
            profitLossLimitDetail: "سفارشات حد سود و ضرر شما",
            history: {
                history: "تاریخچه",
                historyDetail: "تاریخچه همه سفارشات",
                openOrders: "سفارشات",
                openOrdersDetail: "تاریخچه سفارشات",
                profitLossLimit: "حد سود و ضرر",
                profitLossLimitDetail: "تاریخچه حد سود و ضرر",
                otc: "سفارش گذاری",
                otcDetail: "تاریخچه سفارشات OTC",
                transactions: "تراکنش‌ها",
                transactionsDetail: "تاریخچه معاملات"
            }
        },
        investing: {
            investing: "سرمایه‌گذاری",
            staking: "استیکینگ",
            stakingDetail: "انتقال بین اکسکوینو و اکسکوینو مارکت",
            partnershipFunds: "صندوق‌های مشارکت",
            partnershipFundsDetail: "انتقال بین اکسکوینو و اکسکوینو مارکت",
            uranus: "طرح اورانوس",
            uranusDetail: "انتقال بین اکسکوینو و اکسکوینو مارکت"
        },
        blog: "بلاگ"
    },
}