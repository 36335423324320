<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg width="25" height="25" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.25 1.77081L5.3125 2.83331L4.25 3.89581" :stroke="clr" stroke-width="1.0625" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M5.31254 2.83331H3.89587C2.72217 2.83331 1.77087 3.7846 1.77087 4.95831" :stroke="clr"
          stroke-width="1.0625" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.75 15.2292L11.6875 14.1667L12.75 13.1042" :stroke="clr" stroke-width="1.0625" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M11.6875 14.1667H13.1042C14.2779 14.1667 15.2292 13.2154 15.2292 12.0417" :stroke="clr"
          stroke-width="1.0625" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.9725 12.75H4.25004C3.46733 12.75 2.83337 12.116 2.83337 11.3333V8.49998C2.83337 7.71727 3.46733 7.08331 4.25004 7.08331H8.9725C9.75521 7.08331 10.3892 7.71727 10.3892 8.49998V11.3333C10.3892 12.116 9.7545 12.75 8.9725 12.75Z"
          :stroke="clr" stroke-width="1.0625" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M10.3891 9.91667H12.75C13.5327 9.91667 14.1666 9.28271 14.1666 8.5V5.66667C14.1666 4.88396 13.5327 4.25 12.75 4.25H8.02751C7.2448 4.25 6.61084 4.88396 6.61084 5.66667V7.08333"
        :stroke="clr" stroke-width="1.0625" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
