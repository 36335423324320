<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33337 13.3334L4.33337 12.3334"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.33337 14.3334L5.33337 13.3334"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33333 13.3334H4C2.89533 13.3334 2 12.438 2 11.3334"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6666 2.66675L11.6666 3.66675"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6666 1.66675L10.6666 2.66675"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6666 2.66675H12C13.1046 2.66675 14 3.56208 14 4.66675"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6129 12.6129C10.9917 14.2342 8.34175 14.2342 6.72048 12.6129C6.58173 12.4742 6.46737 12.328 6.3705 12.2004C6.2035 11.9805 6.2464 11.6668 6.46632 11.4998C6.68625 11.3328 6.99991 11.3757 7.16691 11.5956C7.25938 11.7174 7.33968 11.8179 7.42759 11.9058C8.65833 13.1365 10.6751 13.1365 11.9058 11.9058C13.1366 10.6751 13.1366 8.65832 11.9058 7.42758C11.8204 7.34213 11.7218 7.26555 11.5985 7.17482C11.376 7.01121 11.3283 6.69825 11.4919 6.47579C11.6555 6.25333 11.9685 6.20562 12.1909 6.36923C12.3196 6.46384 12.4704 6.57792 12.6129 6.72047C14.2342 8.34173 14.2342 10.9916 12.6129 12.6129Z"
      :fill="clr"
    />
    <path
      d="M9.99996 6.33333C9.99996 4.308 8.35863 2.66667 6.33329 2.66667C4.30796 2.66667 2.66663 4.308 2.66663 6.33333C2.66663 8.35867 4.30796 10 6.33329 10C8.35863 10 9.99996 8.35867 9.99996 6.33333"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
