<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6154 1H2.84615C2.35652 1 1.88695 1.1945 1.54073 1.54073C1.1945 1.88695 1 2.35652 1 2.84615V13L3.30769 11.6154L5.61538 13L7.92308 11.6154L10.2308 13V2.38462C10.2308 2.01739 10.3766 1.66521 10.6363 1.40554C10.896 1.14588 11.2482 1 11.6154 1C11.9826 1 12.3348 1.14588 12.5945 1.40554C12.8541 1.66521 13 2.01739 13 2.38462V5.61538H10.2308"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
