<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.23694 3.76085C6.01266 2.18401 8.66547 1.84083 10.8333 3.09246C12.51 4.06046 13.5055 5.75659 13.6496 7.55171L12.901 6.64429L11.8725 7.49278L13.6915 9.69765L14.5784 10.7728L14.8588 9.40751C15.4485 6.53567 14.18 3.48505 11.5 1.93776C8.82007 0.390476 5.54384 0.817173 3.35162 2.76386L4.23694 3.76085ZM2.30854 6.30221L1.42162 5.22712L1.14124 6.59235C0.55147 9.46418 1.82004 12.5148 4.50002 14.0621C7.18 15.6094 10.4562 15.1827 12.6484 13.236L11.7631 12.239C9.9874 13.8158 7.3346 14.159 5.16668 12.9074C3.49005 11.9394 2.49453 10.2433 2.35041 8.44818L3.09901 9.35558L4.12752 8.50711L2.30854 6.30221ZM7 8.00005C7 7.44771 7.44774 7.00005 8 7.00005C8.55227 7.00005 9 7.44771 9 8.00005C9 8.55231 8.55227 9.00005 8 9.00005C7.44774 9.00005 7 8.55231 7 8.00005ZM8 5.66669C6.71134 5.66669 5.66668 6.71138 5.66668 8.00005C5.66668 9.28871 6.71134 10.3334 8 10.3334C9.28867 10.3334 10.3333 9.28871 10.3333 8.00005C10.3333 6.71138 9.28867 5.66669 8 5.66669Z"
      :fill="clr"
    />
  </svg>
</template>
