import $axios from "../../../utiles/axios";
import {
  appendToFormData,
  appendQueryString,
} from "../../../utiles/apiDataFormat";

export default {
  GET_STAKING_DETAIL_BY_PLAN_ID: ({}, { type, ...payload }) => {
    return $axios.post(
      `/staking-api/${type}staking-plans/detail-by-plan-id`,
      appendToFormData(payload)
    );
  },
  GET_SUBSCRIPTION_DETAIL_BY_ID: ({}, payload ) => {
    return $axios.get(
      `/staking-api/staking-subscription/detail-by-id` + 
      appendQueryString(payload)
    );
  },
  GET_ALL_STAKING_PLANS: ({}, { type, ...payload }) => {
    return $axios.post(
      `/staking-api/${type}staking-plans/web/all`,
      appendToFormData(payload)
    );
  },
  GET_ALL_PUBLIC_STAKING_PLANS: ({}, { ...payload }) => {
    return $axios.post(
        `/staking-api/public/staking-plans/web/all`,
        appendToFormData(payload)
    );
  },
  GET_ALL_STAKING_PLANS_WITH_SORT: ({}, {...payload }) => {
    return $axios.post(
        `staking-api/staking-plans/detail/web/page-query`,payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
  },
  GET_STAKING_DETAIL_BY_PLAN_DETAIL_ID: ({}, { type, ...payload }) => {
    return $axios.post(
      `/staking-api/${type}staking-plans/detail-by-planDetail-id`,
      appendToFormData(payload)
    );
  },
  GET_ALL_SUBSCRIBED_STAKING_PLANS: ({}, payload) => {
    return $axios.get(
      "/staking-api/staking-subscription/all" + appendQueryString(payload)
    );
  },
  POST_UPDATE_AUTO_RENEWAL: ({}, payload) => {
    return $axios.post(
      "/staking-api/staking-subscription/update-renewal-status" +
        appendQueryString(payload)
    );
  },
  POST_CANCEL_PLAN: ({}, payload) => {
    return $axios.post(
      "/staking-api/staking-subscription/cancel" + appendQueryString(payload)
    );
  },
  POST_SUBSCRIBE_PLAN: ({}, payload) => {
    return $axios.post(
      "/staking-api/staking-subscription/stake" + appendQueryString(payload)
    );
  },
  GET_STAKING_DISCLAIMER: ({}, planDetailId) => {
    return $axios.get(
      `/staking-api/public/staking-plans/detail/disclaimer?planDetailId=${planDetailId}`
    );
  },
  GET_RENEWAL_HISTORY: ({}, payload) => {
    return $axios.post(
      `/staking-api/staking-subscription/renewal-history` + appendQueryString(payload)
    );
  },
  GET_SUBSCRIPTIONS_BY_PLAN_DETAIL_ID:({},payload)=>{
    return $axios.get(
        `/staking-api/staking-subscription/subscriptions-by-plan-detail-id` + appendQueryString(payload)
    );
  }
};
