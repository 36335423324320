<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.45825 6.20831C3.45825 4.8276 4.57754 3.70831 5.95825 3.70831H14.1094C14.3855 3.70831 14.6094 3.93217 14.6094 4.20831C14.6094 4.48446 14.3855 4.70831 14.1094 4.70831H5.95825C5.22532 4.70831 4.61516 5.23399 4.48423 5.92888H13.33C15.263 5.92888 16.83 7.49588 16.83 9.42887V13.6284C16.83 15.0091 15.7107 16.1284 14.33 16.1284H7.49992C7.22378 16.1284 6.99992 15.9045 6.99992 15.6284C6.99992 15.3522 7.22378 15.1284 7.49992 15.1284H14.33C15.1584 15.1284 15.83 14.4568 15.83 13.6284V9.42887C15.83 8.04816 14.7107 6.92888 13.33 6.92888H4.45825V8.74998C4.45825 9.02612 4.23439 9.24998 3.95825 9.24998C3.68211 9.24998 3.45825 9.02612 3.45825 8.74998V6.42888V6.20831Z"
      :fill="clr"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.69217 9.2614C9.51082 9.05316 9.18971 9.02509 8.97497 9.1987C8.76023 9.37231 8.73316 9.68186 8.91452 9.8901L9.59067 10.6665L3.75524 10.6554C3.47416 10.6548 3.24731 10.8742 3.24854 11.1453C3.24977 11.4164 3.47863 11.6366 3.75971 11.6372L10.6848 11.6503C10.8825 11.6507 11.0618 11.5406 11.1447 11.368C11.2275 11.1954 11.199 10.9915 11.0714 10.8451L9.69217 9.2614ZM4.7092 14.7274C4.89056 14.9357 5.21166 14.9637 5.42641 14.7901C5.64115 14.6165 5.66821 14.307 5.48685 14.0987L4.84955 13.367L10.685 13.3781C10.9661 13.3786 11.1929 13.1592 11.1917 12.8881C11.1904 12.617 10.9616 12.3968 10.6805 12.3963L3.75539 12.3831C3.55772 12.3827 3.37843 12.4928 3.29556 12.6654C3.2127 12.838 3.24126 13.0419 3.3688 13.1883L4.7092 14.7274Z"
      :fill="clr"
    />
  </svg>
</template>
