export default {
    dashboard: "Dashboard",
    exchange: "Market Trades",
    markets: "Markets",
    assets: "Assets",
    wallet: "Wallet",
    transactions: "Transactions",
    deposit: "Deposit",
    transfer: "Transfer",
    withdraw: "Withdraw",
    orders: "Orders",
    openOrders: "Open Orders",
    history: "History",
    ordersHistory: "Orders History",
    preconditionOrdersHistory: "Stop/Limit Orders History",
    stopLimitOrders: "Stop/Limit",
    guide: "Guide",
    excoino: "Go to Excoino Main Page",
    excoinoPanel: "Back to Excoino",
    userProfile: "User Profile",
    affiliateProfile: "Refer Friends",
    support: "Support",
    logout: "Logout",
    register: "Register",
    userCenter: "User Center",
    helpCenter: "Support",
    loginRegister: "Login/Register",
    staking: "Staking",
    activeLanguage: "Active languages in Excoino",
    prices: "Currencies",
    investing: "Investment",
    blog: "Blog",
    menu: {
        dashborad: "Dashboard",
        wallet: {
            wallet: "Wallet",
            main: "Main",
            mainDetail: "Deposit , Withdrawal",
            spot: "Spot (Market)",
            spotDetail: "Spot wallet",
            transfer: "Transfer",
            transferDetail:"Transfer between main and spot",
            transferToMain:"Transfer to main wallet",
            transferToMainDetail:"Transfer assets",
            transferToSpot:"Transfer to Spot Wallet",
            transferToSpotDetail:"Transfer assets"
        },

        dealing: {
            dealing: "Trade",
            fastConvert: "Fast Convert",
            fastConvertDetail: "convert one currency to another",
            spot: "Spot ( Market)",
            spotDetail: "P2P Trade",
            ordering: "OTC Trade",
            orderingDetail: "Place Order in OTC",
            testMarket: "Spot (Demo)",
            testMarketDetail: "Demo P2P Trade",
            perfectMoney: "Perfect Money",
            perfectMoneyDetail: "Buy/Sell Perfect Money"
        },

        markets: "Markets",
        prices: "Currencies",
        orders: {
            orders: "Orders",
            openOrders: "Open orders",
            openOrdersDetail: "Your Open orders",
            profitLossLimit: "Stop/Limit",
            profitLossLimitDetail: "Your Open Stop/Limit Orders",
            history: {
                history: "History",
                historyDetail: "History Of All Orders",
                openOrders: "Orders",
                openOrdersDetail: "History of open orders",
                profitLossLimit: "Stop/Limit",
                profitLossLimitDetail: "History Stop/Limit",
                otc: "OTC Trade",
                otcDetail: "History of OTC",
                transactions: "Transactions",
                transactionsDetail: "History of Transactions"
            }
        },
        investing: {
            investing: "Investment",
            staking: "Stacking",
            stakingDetail: "Stacking , Liquidity pools",
            partnershipFunds: "Mutual funds",
            partnershipFundsDetail: "mutual funds Plan",
            uranus: "Uranus Plan",
            uranusDetail: "Cryptocurrency plans"
        },
        blog: "Blog"
    },
  };
  