<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_4146_617" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.83325 0.916656C1.45254 0.916656 0.333252 2.03594 0.333252 3.41666V3.63722V10.8367C0.333252 12.2174 1.45254 13.3367 2.83325 13.3367H11.205C12.5857 13.3367 13.705 12.2174 13.705 10.8367V6.63722C13.705 4.70422 12.138 3.13722 10.205 3.13722H1.35923C1.49016 2.44233 2.10032 1.91666 2.83325 1.91666H10.9844C11.2605 1.91666 11.4844 1.6928 11.4844 1.41666C11.4844 1.14051 11.2605 0.916656 10.9844 0.916656H2.83325Z"
      />
    </mask>
    <path
      d="M0.333253 1.41666V1.91641H1.33325V1.41666H0.333253ZM1.35923 3.13722L0.376524 2.95207L0.153226 4.13722H1.35923V3.13722ZM1.33325 3.41666C1.33325 2.58823 2.00482 1.91666 2.83325 1.91666V-0.0833435C0.900255 -0.0833435 -0.666748 1.48366 -0.666748 3.41666H1.33325ZM1.33325 3.63722V3.41666H-0.666748V3.63722H1.33325ZM1.33325 10.8367V3.63722H-0.666748V10.8367H1.33325ZM2.83325 12.3367C2.00482 12.3367 1.33325 11.6651 1.33325 10.8367H-0.666748C-0.666748 12.7697 0.900257 14.3367 2.83325 14.3367V12.3367ZM11.205 12.3367H2.83325V14.3367H11.205V12.3367ZM12.705 10.8367C12.705 11.6651 12.0334 12.3367 11.205 12.3367V14.3367C13.138 14.3367 14.705 12.7697 14.705 10.8367H12.705ZM12.705 6.63722V10.8367H14.705V6.63722H12.705ZM10.205 4.13722C11.5857 4.13722 12.705 5.25651 12.705 6.63722H14.705C14.705 4.15194 12.6902 2.13722 10.205 2.13722V4.13722ZM1.35923 4.13722H10.205V2.13722H1.35923V4.13722ZM2.83325 0.916656C1.6106 0.916656 0.594841 1.79334 0.376524 2.95207L2.34194 3.32237C2.38548 3.09132 2.59004 2.91666 2.83325 2.91666V0.916656ZM10.9844 0.916656H2.83325V2.91666H10.9844V0.916656ZM10.4844 1.41666C10.4844 1.14051 10.7083 0.916656 10.9844 0.916656V2.91666C11.8128 2.91666 12.4844 2.24508 12.4844 1.41666H10.4844ZM10.9844 1.91666C10.7083 1.91666 10.4844 1.6928 10.4844 1.41666H12.4844C12.4844 0.588229 11.8128 -0.0833435 10.9844 -0.0833435V1.91666ZM2.83325 1.91666H10.9844V-0.0833435H2.83325V1.91666Z"
      :fill="clr"
      mask="url(#path-1-inside-1_4146_617)"
    />
  </svg>
</template>
