<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.958374 6.23364V10.7483C0.959166 11.3605 2.19926 11.857 3.72997 11.857C5.26067 11.857 6.50077 11.3605 6.50156 10.7483V6.23364"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.959106 8.49109C0.959106 9.10322 2.19999 9.59973 3.7307 9.59973C5.26141 9.59973 6.50229 9.10322 6.50229 8.49109"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.959166 6.23364C0.959166 6.84576 2.20005 7.34227 3.73076 7.34227C5.26147 7.34227 6.50156 6.84576 6.50156 6.23364C6.50156 5.62072 5.25988 5.125 3.72997 5.125C2.20005 5.125 0.959166 5.62151 0.958374 6.23364"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.5 8.41077V10.5505C6.50079 11.1626 7.74088 11.6591 9.27159 11.6591C10.8023 11.6591 12.0424 11.1626 12.0432 10.5505V8.41077"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.50079 8.41076C6.50079 9.02289 7.74168 9.5194 9.27238 9.5194C10.8031 9.5194 12.0432 9.02289 12.0432 8.41076C12.0432 7.79784 10.8015 7.30212 9.27159 7.30212C7.74168 7.30212 6.50079 7.79863 6.5 8.41076"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M7.99951 6.99927L7.99951 3.99927" :stroke="clr" />
    <path
      d="M13.3162 0.949331C13.3162 0.949331 12.7803 2.50143 11.4598 3.24487C10.1394 3.98831 8.53443 3.64156 8.53443 3.64156C8.53443 3.64156 9.0703 2.08946 10.3907 1.34602C11.7112 0.602582 13.3162 0.949331 13.3162 0.949331Z"
      :stroke="clr"
      stroke-linejoin="round"
    />
    <path
      d="M3.54708 1.42385C3.54708 1.42385 4.02015 2.83253 5.21848 3.56652C6.41682 4.30051 7.88663 4.08185 7.88663 4.08185C7.88663 4.08185 7.41357 2.67317 6.21523 1.93919C5.0169 1.2052 3.54708 1.42385 3.54708 1.42385Z"
      :stroke="clr"
      stroke-linejoin="round"
    />
  </svg>
</template>
