<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33337 12.6667L10.9407 5.05939"
      :stroke="clr"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.99996 4.33333C5.99996 3.41273 5.2539 2.66667 4.33329 2.66667C3.41269 2.66667 2.66663 3.41273 2.66663 4.33333C2.66663 5.25394 3.41269 6 4.33329 6C5.2539 6 5.99996 5.25394 5.99996 4.33333"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3333 11.6666C13.3333 10.746 12.5873 9.99998 11.6667 9.99998C10.7461 9.99998 10 10.746 10 11.6666C10 12.5873 10.7461 13.3333 11.6667 13.3333C12.5873 13.3333 13.3333 12.5873 13.3333 11.6666"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.32196 3L12.8667 3C12.9404 3 13.0001 3.0597 13.0001 3.13333V6.67811C13.0001 6.79689 12.8564 6.85638 12.7724 6.77239L9.22767 3.22761C9.14368 3.14362 9.20317 3 9.32196 3Z"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
