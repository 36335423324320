export default {
  status_active: "فعال",
  status_cancel: "لغو شده",
  status_terminated: "پایان یافته",
  noData_table: "اطلاعاتی برای نمایش وجود ندارد",
  cancel_plan: "انصراف از طرح",
  cancel_plan_text:
    "با انصراف از طرح، پاداش دوره جاری به شما تعلق نخواهد گرفت و ممکن است هزینه انصراف از طرح استیکینگ از شما کسر شود. توصیه می‌کنیم به منظور کسب پاداش تا پایان دوره جاری از طرح انصراف ندهید.آیا برای انصراف از طرح اطمینان دارید؟",
  back: "بازگشت",
  activate_auto_renewal: "فعال سازی تمدید خودکار",
  deactivate_auto_renewal: "غیرفعال سازی تمدید خودکار",
  active_auto_renewal_text:
    "با  تمدید خودکار در پایان دوره استیکینگ، دارایی استیک شده شما به دوره بعدی منتقل می‌شود.توجه داشته باشید پاداش استیکینگ هر دوره در پایان همان دوره آزاد شده و به کیف پول شما واریز می‌شود و مبلغ پاداش به دوره استیکینگ بعدی منتقل نخواهد شد.",
  inactive_auto_renewal_text:
    "با لغو تمدید خودکار در پایان دوره استیکینگ، دارایی استیک شده و پاداش شما آزاد می‌شود و در دوره بعدی این استیکینگ شرکت داده نخواهید شد.",
  activate: "فعال سازی",
  deactivate: "غیرفعال سازی",
  staking_plans: "طرح‌های مشارکت",
  success_request: "درخواست شما با موفقیت ثبت شد.",
  unknown_mistake: "خطای ناشناخته",
  my_plans: "مشارکت های من",
  gain_reward: "استخرهای مشارکت و استیکینگ",
  gain_reward_text:
    "شما با شرکت در طرح‌های متنوع استخرهای مشارکت و استیکینگ اکسکوینو می‌‌توانید با سپردن دارایی‌های کریپتویی خود، کسب پاداش کنید. اکسکوینو تلاش کرده با ساده‌سازی فرایند سپرده‌گذاری, امکانات مختلف دنیای کریپتو را در اختیار کاربران این پلتفرم قرار دهد.",
  plan_duration: "مدت طرح:",
  day: "روز",
  plan_status: "وضعیت طرح:",
  exist: "فعال",
  not_exist: "غیرفعال",
  approximate_active_reward: "پاداش تقریبی فعال، APR (سالانه)",
  rewards_earned: "پاداش به دست آمده تا امروز",
  participation_rate: "میزان مشارکت شما",
  cancel_fee: "هزینه انصراف از طرح",
  renewal_fee: "هزینه تمدید خودکار",
  cancel: "انصراف",
  free: "رایگان",
  remain_duration: "روزهای مانده / روزهای کل طرح",
  start_date: "تاریخ پیوستن به طرح",
  cancel_date: "تاریخ انصراف",
  release_duration: "مدت زمان انتظار برای آزاد سازی دارایی",
  cancel_release_duration: "مدت زمان انتظار آزاد سازی دارایی پس از انصراف",
  auto_renewal: "تمدید خودکار",
  date: "تاریخ",
  reward_percent: "درصد پاداش",
  earned_money: "مبلغ دریافتی روزانه",
  reward_table: "جدول پاداش",
  yearly_active_apr: "APR فعال (بازده درصدی سالانه)",
  min_max_apr: "حداقل/حداکثر APR",
  remained_plan: "باقی مانده طرح",
  registration_time: "مهلت ثبت‌نام",
  min_max_input: "حداقل/ حداکثر مقدار ورودی",
  to: "تا",
  registration_fee: "هزینه ورود به طرح",
  cancelable: "امکان انصراف از طرح",
  has: "دارد",
  not_has: "ندارد",
  reward: "پاداش",
  daily: "روزانه",
  weekly: "هفتگی",
  monthly: "ماهانه",
  yearly: "سالانه",
  balance: "موجودی",
  increase_balance: "افزایش موجودی",
  stake_amount: "میزان مشارکت",
  insufficient_balance: "موجودی ناکافی",
  insufficient_min_amount: "کمتر از مقدار مجاز",
  insufficient_max_amount: "بیشتر از مقدار مجاز",
  terms_1: "قوانین",
  terms_2: "را قبول دارم",
  stake: "استیک",
  apr_reward: "APR یا پاداش",
  apr_reward_text:
    "نشان‌دهنده نرخ پاداش یک سرمایه‌گذاری در طول یک سال است. برای مثال، اگر شما ۱۰۰۰ توکن را با APR ۱۰٪ سرمایه‌گذاری کنید، در ۶ ماه ۱۰۵۰ و در پایان سال ۱۱۰۰ توکن خواهید داشت.",
  fixed_stake: "پاداش روزشمار با درصد سالیانه یکنواخت",
  fixed_stake_text:
    "شما با شرکت در طرح‌های مشارکت با پاداش ثابت سالیانه از یک نرخ تقریبی ثابت، در طول دوره پاداش دریافت می‌کنید.",
  flexible_stake: "پاداش روزشمار با درصد سالیانه متغیر",
  flexible_stake_text:
    "در این طرح‌ها اکسکوینو یک پاداش حداقلی را تضمین و بر اساس شرایط شبکه، بازار و یا طرح ممکن است میزان پاداش بیشتر باشد. پاداش‌ها به صورت روزشمار با درصد فعال همان روز محاسبه می‌شود.",
  earned_reward: "پاداش (تا امروز)",
  remained_time: "مانده تا پایان",
  hour: "ساعت",
  minute: "دقیقه",
  view_detail: "مشاهده جزئیات",
  fixed_apr: "(ثابت) APR",
  max_apr: "حداکثر APR",
  active_apr: "فعال APR",
  min_apr: "حداقل APR",
  registered_plan: "شما در این طرح شرکت کرده اید",
  min_stake_amount: "حداقل مقدار ورود",
  show_more: "نمایش بیشتر",
  registration_expired: "پایان مهلت ثبت‌نام",
  registration_not_started: "ثبت نام آغاز نشده",
  filled_capacity: "ظرفیت تکمیل شده",
  enter_plan: "شرکت در طرح",
  login: "لاگین کنید",
  cancel_reward_earned: "پاداش به دست آمده",
  released_amount: "دارایی آزاد شده",
  max_capacity: "ظرفیت کل",
  renewal_table: "تاریخ محاسبه پاداش",
  renewal_date: "شروع دوره پاداش",
  renewal_status: "وضعیت",
  instant: "آنی",
  stake_in_plan:"مشارکت در طرح",
  my_stakes:"مشارکت های من",
  plan_name:"مدت طرح",
  plan_status_table: "وضعیت طرح",
  all_balance: "همه موجودی",
  most_profit: "بیشترین سود",
  lowest_profit: "کمترین سود",
  show_currencies: "نمایش ارزها",
  name_plan: "نام طرح",
  time_frame : "بازه زمانی",
};
