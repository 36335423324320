<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66663 5.70371H13.3333"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.81477 11.6296H4.4444C3.46256 11.6296 2.66663 10.8337 2.66663 9.85183V5.11109C2.66663 4.12925 3.46256 3.33331 4.4444 3.33331H11.5555C12.5374 3.33331 13.3333 4.12925 13.3333 5.11109V9.85183"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="10.5" cy="11.5" r="2" :stroke="clr" />
    <path
      d="M11.4894 11.699L10.4884 11.7203L10.0279 11.0004"
      :stroke="clr"
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
