export default {
    addFavorError: "امکان افزودن به علاقه مندی ها وجود ندارد",
    addFavorWarning:"برای افزودن علاقمندی ها باید از طریق اکسکوینو وارد شوید",
    newPrice: "آخرین قیمت",
    change: "تغییرات روزانه",
    high: "بالاترین قیمت",
    low: "پایین‌ترین قیمت",
    exchangeNum: "حجم معاملات",
    toSubmitOrder: "برای ثبت سفارش در اکسکوینو مارکت",
    loginToExcoinoMarket: "وارد شوید",
    or: "یا",
    createNewAccount: "ایجاد حساب کاربری جدید",
    excoinoMarketChart: "نمودار اکسکوینو‌ مارکت",
    globalChart: "نمودار جهانی",
    sell: "فروش",
    buy: "خرید",
    price: "قیمت",
    amount: "مقدار",
    transfer: "انتقال",
    balanceIncrease: "افزایش موجودی",
    openOrders: "سفارشات باز",
    stopLimit: "حد سود/ضرر",
    limitOrder: "سفارش محدود",
    marketOrder: "سفارش آنی",
    limit: "محدود",
    instant: "آنی",
    ocoLimit: "محدود (OCO)",
    ocoStopLimit: "حد سود/ضرر (OCO)",
    turnover: "حجم معاملات",
    type: "نوع",
    pairCoin: "جفت ارز",
    direction: "جهت معامله",
    activationPrice: "قیمت فعال‌سازی",
    description: "توضیحات",
    cancelOrder: "لغو سفارش",
    cancelOrderConfirmation: "آیا از لغو سفارش خود مطمئن هستید؟",
    confirm: "تایید",
    cancel: "لغو",
    ordersHistory: "تاریخچه سفارشات",
    orderDetails: "جزییات سفارش",
    refCode: "کد پیگیری",
    coin: "رمزارز",
    symbol: "جفت‌ارز",
    lastPrice: "آخرین قیمت",
    dayChange: "تغییرات روزانه",
    percent: "درصد معامله شده",
    market: "بازارها",
    favorite: "علاقه‌مندی",
    do_favorite: "افزودن به علاقه‌مندی‌ها",
    cancel_favorite: " لغو علاقه‌مندی",
    orderType: "نوع سفارش",
    dealAmount: "حجم معاملات",
    vol: "حجم ۲۴ ساعته",
    limited_price: "سفارش محدود",
    market_price: "سفارش آنی",
    stop_order_price: "سفارش حد سود/ضرر",
    fees_rate: "نرخ کارمزد",
    fee_amount: "میزان کارمزد",
    balance: "موجودی ها",
    canUse: "موجودی",
    recharge: "واریز",
    buyPrice: "قیمت خرید",
    buyAmount: "مقدار خرید",
    averageTradePrice: "میانگین قیمت معامله‌شده",
    buyTip: "قیمت بازار",
    sellPrice: "قیمت فروش",
    stopPrice: "قیمت فعال سازی",
    sellAmount: "مقدار فروش",
    sellTip: "قیمت بازار",
    stopLimitDelegation: "حد سود/ضرر",
    preconditionOrdersHistory: "تاریخچه حد سود/ضرر",
    num: "مقدار",
    fee: "کارمزد",
    buySell: "خرید/فروش",
    time: "زمان",
    stall: "متوقف",
    total: "مجموع",
    estimatedReceipt: "دریافتی تقریبی شما",
    traded: "مقدار معامله‌شده",
    action: "عملیات",
    undo: "لغو",
    more: "جزئیات",
    done: "مقدار معامله شده",
    status: "وضعیت",
    finished: "تکمیل شده",
    canceled: "لغو شده",
    expired: "منقضی شده",
    parted: "جدا شده",
    ordered: "سفارش شده",
    enterBuyAmount: "لطفا مقدار خرید را وارد کنید",
    success: "عملیات موفق!",
    enterSellAmount: "لطفا مقدار فروش را وارد کنید",
    successCancel: "لغو سفارش موفقیت آمیز است",
    marketPrice: "قیمت بازار",
    history: "تاریخچه",
    preconditionOrders: "سفارشات حد سود/ضرر",
    orders: "سفارشات",
    demoMarket:"بازار آزمایشی",
    demoTrading:"معاملات آزمایشی - Demo Trading",
    modalText01:"بازار دمو یک بازار آموزشی و تستی است که به شما اجازه می‌دهد با ارزهای مجازی مانند V-USDT، V-BTC و V-ETH معامله کنید. این ارزها فقط برای آزمایش و یادگیری هستند و هیچ ارزش واقعی ندارند. شما نمی‌توانید آن‌ها را به ریال یا دلار تبدیل کنید یا از سامانه خارج کنید. شما فقط می‌توانید آن‌ها را در بازار دمو با یکدیگر مبادله کنید.",
    modalText02:"همه کاربران بعد از ثبت نام به صورت رایگان مقداری از ارز V-USDT دریافت می‌کنید. شما می‌توانید با استفاده از این ارز، سفارش خرید یا فروش برای ارزهای دیگر در بازار دمو ثبت کنید. برای مثال، شما می‌توانید V-USDT خود را به V-BTC تبدیل کنید. برای این کار، شما باید قیمت و تعداد مورد نظر خود را وارد کنید و سپس سفارش خود را تأیید کنید. سامانه به صورت خودکار سفارش شما را با سفارشات مناسب دیگر کاربران همخوانی می‌دهد و معامله را انجام می‌دهد.",
    modalText03:"شما با استفاده از این بازار میتوانید بدون در خطر انداختن دارایی واقعی خود با قابلیت های سیستم آشنا شده و سپس به معاملات واقعی بپردازید.",
    expand: {
        time: "زمان",
        price: "قیمت",
        amount: "مقدار",
        fee: "کارمزد",
        volume: "حجم معملات"
    },
    day: "روز",
    hours: "ساعت",
    minutes: "دقیقه",
    seconds: "ثانیه",
    noOrders: "سفارشی وجود ندارد",
    realtime: "بلادرنگ",
    kline: "نمودار",
    depth: "نمودار عمق",
    publishState0: "در آستانه شروع است. لطفا صبر کنید....",
    publishState1: "در شرف پایان است. لطفا صبر کنید...",
    publishState2: "ادغام سفارش ، لطفا صبر کنید ...",
    publishState3: "ادغام به پایان رسید ، لطفا معامله رایگان انجام دهید ....",
    publishCountTxt0: "شروع پس از",
    publishCountTxt1: "اتمام پس از",
    publishCountTxt2: "زمان باقی‌مانده تا شروع معامله",
    publishCountTxt3: "فعالیت تمام شده!",
    insufficientBalance: "موجودی کیف پول شما کافی نیست",
    limitOrderPrice: "قیمت سفارش محدود",
    enterLimitOrderPrice: "لطفا قیمت سفارش محدود را وارد کنید.",
    enterActivationPrice: "لطفا قیمت فعال‌سازی را وارد کنید.",
    enterBuyPrice: "لطفا قیمت خرید را وارد کنید.",
    enterSellPrice: "لطفا قیمت فروش را وارد کنید.",
    ocoOrderInfoModal: {
        title: "نحوه کار با قابلیت OCO",
        buyOrder: "سفارش خرید",
        sellOrder: "سفارش فروش",
        ocoTrades: "معاملات OCO: به معنی این است که هنگام معامله بصورت همزمان میتوان دو سفارش (سفارش‌گذاری) قرار داد، حالت اول بصورت استاپ و حالت دوم به صورت لیمیت می‌باشد .",
        buyTrades: "معاملات خرید (buy) :",
        buyStop: "حالت استاپ : در این حالت سفارش معامله‌ی استاپ بالاتر از قیمت فعلی قرار میگیرد.",
        buyLimit: "حالت لیمیت : در این حالت سفارش معامله‌ی لیمیت  پایینتر از قیمت فعلی مارکت قرار میگیرد.",
        desc1: "قیمت استاپ در معامله خرید (نقطه B)  باید بالاتر از قیمت فعلی مارکت برای سفارش خرید باشد و قیمت محدود (نقطه C) باید پایینتر از قیمت فعلی باشد.",
        notice: "توجه :",
        desc2: "سفارش استاپ بدین صورت است که هنگامی که قیمت به نقطه B (3000)میرسد سفارش فعال می‌شود و همزمان سفارش محدود لغو خواهد شد. در صورت دیگر اگر قیمت تا محدوده 1500(نقطه C) یا کمتر کاهش یابد سفارش محدود (لیمیت) فعال خواهد شد و سفارش استاپ لغو خواهد شد.",
        desc3: "معاملات OCO: به معنی این است که هنگام معامله بصورت همزمان میتوان دو سفارش (سفارش‌گذاری) قرار داد، حالت اول بصورت استاپ و حالت دوم به صورت لیمیت می‌باشد .",
        sellTrades: "معاملات فروش (Sell):",
        sellStop: "حالت استاپ : در این حالت سفارش معامله‌ی استاپ پایینتر از قیمت فعلی قرار میگیرد.",
        sellLimit: "حالت لیمیت : در این حالت سفارش معامله‌ی لیمیت بالاتر از قیمت فعلی مارکت قرار میگیرد.",
        desc4: "قیمت استاپ در معامله فروش (نقطه C) باید پایین‎تر از قیمت فعلی مارکت برای سفارش فروش باشد و قیمت محدود (نقطه B) باید بالاتر از قیمت فعلی باشد.",
        desc5: "سفارش استاپ بدین صورت است که هنگامی که قیمت به نقطه C\n" +
            "  (1500) می‌رسد سفارش فعال میشود و همزمان سفارش محدود لغو خواهد شد. در صورت دیگر اگر قیمت تا محدوده  نقطه B ا(3000) یا بیشتر افزایش یابد، سفارش محدود (لیمیت) فعال خواهد شد و سفارش استاپ لغو خواهد شد.",
        understood: "متوجه شدم"
    },
    preconditionOrderInfoModal: {
        title: "نحوه کار با قابلیت حد سود/ضرر",
        buyOrder: "سفارش خرید",
        sellOrder: "سفارش فروش",
        desc1: "قیمت ارز مورد نظر شما در نقطه A میباشد و درصورت رسیدن به نقطه C  سفارش خرید شما انجام خواهد شد که در اینصورت میزان بیشتری از ارز مورد نظر را نسبت به نقطه A  دریافت خواهید کرد که منجر به سود خواهد شد و در صورت رسیدن قیمت به نقطه B  سفارش خرید شما انجام و از خرید در قیمت های بالاتر از نقطه B  و ضرر بیشتر جلوگیری خواهد کرد.",
        desc2: "نکته مهم : نقطه فعالسازی  پیش شرط انجام معامله میباشد و پس از رسیدن قیمت به نقطه فعالسازی شرط دوم که رسیدن به قیمت معامله (B یا C) است لازمه انجام معامله میباشد .\n" +
            "نقاط فعالسازی (خطوط خط‌چین) میتواند بالاتر یا پایین‌تر از قیمت انجام معامله باشد ،دقت نمایید که انتخاب نقطه فعالسازی مهم میباشد زیرا ممکن است قیمت به نقطه فعالسازی برسد ولی به نقطه انجام معامله نرسیده و معامله صورت نگیرد.",
        desc3: "* برای هرکدام از سفارشات B یا C باید سفارش جداگانه ایجاد شود.",
        desc4: "قیمت خرید ارز شما در نقطه A  میباشد و درصورت رسیدن به نقطه B سفارش فروش شما با سود همراه خواهد بود\n" +
            "و در صورت رسیدن قیمت به نقطه C سفارش فروش شما انجام خواهد شد و از ضرر بیشتر جلوگیری خواهد شد .",
        desc5: "نکته مهم :  نقطه فعالسازی پیش شرط انجام معامله میباشد و پس از رسیدن قیمت به نقطه فعالسازی شرط دوم که رسیدن به قیمت معامله  ( B  یا   C) است لازمه انجام معامله میباشد .نقاط فعالسازی (خطوط خطچین) میتواند بالاتر یا پایین تر از قیمت انجام معامله باشد ،دقت نمایید که انتخاب نقطه فعالسازی مهم میباشد زیرا ممکن است قیمت به نقطه فعالسازی برسد ولی به نقطه انجام معامله نرسیده و معامله صورت نگیرد.",
        desc6: "* برای هرکدام از سفارشات B یا C باید سفارش جداگانه ایجاد شود.",
        understood: "متوجه شدم"
    }
}