export default {
    pagesTitle: "Excoino | Currency | Excoino official website - global bitcoin trading platform | Global digital currency trading platform",
    loginTip: "Please login first",
    searchPlaceHolder: "Search...",
    logout: "Logout",
    tip: "Important Tips:",
    nodata: "No data recorded",
    more: "All",
    please: "Please",
    pleaseSelect: "Select",
    loadingText: "Loading results, please wait",
    getRealized: "Got it",
    excoino:"Excoino",
    messages: "Messages",
    noMessage: "You have no messages",
    searchNameCoin:"Search by currency name or abbreviation...",
    show:"Show",
    removeFilter:"Remove Filter",
    showAllCoins:"Show All Coins"

  };
