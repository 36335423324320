import affiliate from "./affiliate";
import deposit from "./deposit";
import common from "./common";
import dashboard from "./dashboard";
import exchange from "./exchange";
import guidePage from "./guidePage";
import header from "./header";
import marketsPage from "./marketsPage";
import profile from "./profile";
import stopLimitOrders from "./stopLimitOrders";
import termsModal from "./termsModal";
import termsPage from "./termsPage";
import transactions from "./transactions";
import withdraw from "./withdraw";
import staking from "./staking";
import assets from "./assets";
import openOrders from "./openOrders";
import ordersHistory from "./ordersHistory";
import preconditionsHistory from "./preconditionsHistory";

export default {
    affiliate,
    assets,
    deposit,
    common,
    dashboard,
    exchange,
    guidePage,
    header,
    marketsPage,
    openOrders,
    ordersHistory,
    preconditionsHistory,
    profile,
    stopLimitOrders,
    termsPage,
    transactions,
    withdraw,
    staking,
    termsModal
}