export default {
  pagesTitle: "Excoino | العملة | الموقع الرسمي لـ Excoino - منصة تداول البيتكوين العالمية | منصة تداول العملات الرقمية العالمية",
  loginTip: "يرجى تسجيل الدخول أولاً",
  searchPlaceHolder: "بحث...",
  logout: "تسجيل الخروج",
  tip: "نصائح مهمة:",
  nodata: "لا توجد بيانات مسجلة",
  more: "الكل",
  please: "يرجى",
  pleaseSelect: "اختر",
  loadingText: "جاري تحميل النتائج، يرجى الانتظار",
  getRealized: "فهمت",
  excoino:"Excoino",
  show:"يعرض",
  removeFilter:"إزالة عامل التصفية",
  showAllCoins:"عرض كافة العملات"
};
