import {mapGetters} from "vuex";
import MainWallet from "@/assets/images/header/mainWallet.vue";
import SpotWallet from "@/assets/images/header/spotWallet.vue";
import FastConvert from "@/assets/images/header/fastConvert.vue";
import SpotMarket from "@/assets/images/header/spotMarket.vue";
import TestSpot from "@/assets/images/header/testSpot.vue";
import Ordering from "@/assets/images/header/ordering.vue";
import PerfectMoney from "@/assets/images/header/perfectMoney.vue";
import OpenOrders from "@/assets/images/header/openOrders.vue";
import ProfitLossLimit from "@/assets/images/header/profitLossLimit.vue";
import History from "@/assets/images/header/history.vue";
import OpenOrdersHistory from "@/assets/images/header/openOrdersHistory.vue";
import ProfitLossLimitHistory from "@/assets/images/header/profitLossLimitHistory.vue";
import TransactionsHistory from "@/assets/images/header/transactionsHistory.vue";
import Staking from "@/assets/images/header/staking.vue";
import PartnershipFunds from "@/assets/images/header/partnershipFunds.vue";
import Uranus from "@/assets/images/header/uranus.vue";
import TransferIcon from "@/assets/images/header/transferIcon.vue";

export default {
    data() {
        return {
            baseUrl: process.env.VUE_APP_EXCOINO_PANEL_URL+'/panel',
        }
    },
    name: "header-nav",
    components: {
        TransferIcon,
        Uranus,
        PartnershipFunds,
        Staking,
        TransactionsHistory,
        ProfitLossLimitHistory,
        OpenOrdersHistory,
        History,
        ProfitLossLimit,
        OpenOrders, PerfectMoney, Ordering, TestSpot, SpotMarket, FastConvert, SpotWallet, MainWallet},
    props: {
        isWithdrawActive: {
            type: Boolean,
        },
        // isDepositActive: {
        //   type: Boolean,
        // },
        activeLinkName: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            getLoginStatus: "global/IS_LOGIN",
        }),
        isLogin() {
            return this.getLoginStatus;
        },
    },
}
;
