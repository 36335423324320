<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 15.5H5C3.34325 15.5 2 14.1568 2 12.5V5C2 3.34325 3.34325 2 5 2H12.5C14.1568 2 15.5 3.34325 15.5 5V12.5C15.5 14.1568 14.1568 15.5 12.5 15.5Z"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 9.125L7.25 5.375L10.25 8.375L15.5 4.625"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.6225 12.7211H4.75V10.75H5.62971C5.87728 10.75 6.0904 10.7895 6.26907 10.8684C6.44773 10.9467 6.58514 11.0593 6.68128 11.2062C6.77823 11.3531 6.8267 11.529 6.8267 11.7336C6.8267 11.939 6.77823 12.1154 6.68128 12.263C6.58514 12.4106 6.44693 12.5238 6.26666 12.6027C6.0872 12.6817 5.87247 12.7211 5.6225 12.7211ZM5.27038 12.3641H5.60087C5.7547 12.3641 5.88409 12.3422 5.98905 12.2986C6.09481 12.2543 6.17412 12.186 6.227 12.0936C6.28068 12.0006 6.30752 11.8806 6.30752 11.7336C6.30752 11.588 6.28068 11.469 6.227 11.3766C6.17412 11.2842 6.09521 11.2162 5.99025 11.1725C5.88529 11.1289 5.7559 11.1071 5.60207 11.1071H5.27038V12.3641Z"
      :fill="clr"
    />
    <path
      d="M7.94229 12.75C7.7524 12.75 7.58896 12.7192 7.45196 12.6576C7.31575 12.5954 7.2108 12.5075 7.13709 12.3939C7.06338 12.2797 7.02652 12.1446 7.02652 11.9887C7.02652 11.8366 7.06338 11.7032 7.13709 11.5883C7.2108 11.4735 7.31455 11.3839 7.44835 11.3198C7.58295 11.2556 7.74079 11.2235 7.92186 11.2235C8.04364 11.2235 8.15701 11.2393 8.26196 11.2707C8.36772 11.3015 8.45986 11.348 8.53838 11.4103C8.61769 11.4725 8.67939 11.5508 8.72345 11.6451C8.76752 11.7388 8.78955 11.8485 8.78955 11.9743V12.0869H7.23083V11.8328H8.30763C8.30763 11.7737 8.29161 11.7214 8.25956 11.6759C8.22751 11.6303 8.18305 11.5947 8.12616 11.5691C8.07008 11.5427 8.00478 11.5296 7.93027 11.5296C7.85255 11.5296 7.78365 11.544 7.72356 11.5729C7.66427 11.6011 7.6178 11.6393 7.58415 11.6874C7.5505 11.7349 7.53328 11.7879 7.53248 11.8462V12.0878C7.53248 12.161 7.5493 12.2242 7.58295 12.2774C7.6174 12.3307 7.66587 12.3718 7.72837 12.4006C7.79086 12.4295 7.86497 12.4439 7.9507 12.4439C8.00758 12.4439 8.05966 12.4375 8.10693 12.4247C8.1542 12.4119 8.19466 12.3926 8.22831 12.3669C8.26196 12.3413 8.2876 12.3098 8.30523 12.2726L8.77874 12.2976C8.7547 12.3888 8.70543 12.4683 8.63091 12.5363C8.5572 12.6037 8.46186 12.6563 8.34489 12.6942C8.22871 12.7314 8.09451 12.75 7.94229 12.75Z"
      :fill="clr"
    />
    <path
      d="M9.02212 12.7211V11.2428H9.51005V11.5036H9.53168C9.57014 11.417 9.63424 11.3487 9.72397 11.2986C9.8137 11.2486 9.92106 11.2235 10.046 11.2235C10.1726 11.2235 10.2804 11.2489 10.3693 11.2996C10.4583 11.3496 10.5176 11.4176 10.5472 11.5036H10.5664C10.6041 11.4189 10.6722 11.3512 10.7707 11.3005C10.8701 11.2492 10.9875 11.2235 11.1229 11.2235C11.2951 11.2235 11.4349 11.2675 11.5423 11.3554C11.6504 11.4427 11.7045 11.5665 11.7045 11.7269V12.7211H11.1938V11.8077C11.1938 11.7256 11.1665 11.664 11.112 11.623C11.0576 11.5819 10.9895 11.5614 10.9077 11.5614C10.8148 11.5614 10.7423 11.5851 10.6902 11.6326C10.6381 11.6794 10.6121 11.7413 10.6121 11.8183V12.7211H10.1158V11.7991C10.1158 11.7266 10.0897 11.6688 10.0376 11.6258C9.98636 11.5829 9.91866 11.5614 9.83453 11.5614C9.77765 11.5614 9.72637 11.5729 9.6807 11.596C9.63584 11.6185 9.60018 11.6502 9.57375 11.6913C9.54731 11.7317 9.53409 11.7792 9.53409 11.8337V12.7211H9.02212Z"
      :fill="clr"
    />
    <path
      d="M12.8414 12.75C12.6548 12.75 12.4933 12.7182 12.3571 12.6547C12.2217 12.5906 12.1172 12.5014 12.0435 12.3872C11.9697 12.2723 11.9329 12.1392 11.9329 11.9877C11.9329 11.835 11.9697 11.7016 12.0435 11.5873C12.1172 11.4725 12.2217 11.3833 12.3571 11.3198C12.4933 11.2556 12.6548 11.2235 12.8414 11.2235C13.0281 11.2235 13.1892 11.2556 13.3246 11.3198C13.4608 11.3833 13.5657 11.4725 13.6394 11.5873C13.7131 11.7016 13.75 11.835 13.75 11.9877C13.75 12.1392 13.7131 12.2723 13.6394 12.3872C13.5657 12.5014 13.4608 12.5906 13.3246 12.6547C13.1892 12.7182 13.0281 12.75 12.8414 12.75ZM12.8438 12.4324C12.9288 12.4324 12.9997 12.4131 13.0566 12.3746C13.1135 12.3355 13.1563 12.2822 13.1852 12.2149C13.2148 12.1475 13.2296 12.0708 13.2296 11.9848C13.2296 11.8989 13.2148 11.8222 13.1852 11.7548C13.1563 11.6874 13.1135 11.6342 13.0566 11.595C12.9997 11.5559 12.9288 11.5363 12.8438 11.5363C12.7581 11.5363 12.686 11.5559 12.6275 11.595C12.5698 11.6342 12.5262 11.6874 12.4965 11.7548C12.4677 11.8222 12.4533 11.8989 12.4533 11.9848C12.4533 12.0708 12.4677 12.1475 12.4965 12.2149C12.5262 12.2822 12.5698 12.3355 12.6275 12.3746C12.686 12.4131 12.7581 12.4324 12.8438 12.4324Z"
      :fill="clr"
    />
  </svg>
</template>
