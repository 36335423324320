<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.875 8.66644L5.95833 9.74978L4.875 10.8331"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.16675 7.04144V8.66644C2.16675 9.26498 2.65154 9.74978 3.25008 9.74978H5.95841"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.12508 4.33311L7.04175 3.24978L8.12508 2.16644"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.8334 5.95833V4.33333C10.8334 3.73479 10.3486 3.25 9.75008 3.25H7.04175"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.33333 5.41667H2.70833C2.10979 5.41667 1.625 4.93188 1.625 4.33333V2.70833C1.625 2.10979 2.10979 1.625 2.70833 1.625H4.33333C4.93188 1.625 5.41667 2.10979 5.41667 2.70833V4.33333C5.41667 4.93188 4.93188 5.41667 4.33333 5.41667Z"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2916 11.3752H8.66659C8.06804 11.3752 7.58325 10.8904 7.58325 10.2919V8.66689C7.58325 8.06835 8.06804 7.58356 8.66659 7.58356H10.2916C10.8901 7.58356 11.3749 8.06835 11.3749 8.66689V10.2919C11.3749 10.8904 10.8901 11.3752 10.2916 11.3752Z"
      :stroke="clr"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
