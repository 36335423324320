<script>
export default {
  props: {
    clr: {
      default: "#fff"
    }
  }
};
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_3956_46147" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.8335 0.916992C1.45278 0.916992 0.333496 2.03628 0.333496 3.41699V3.63756V10.837C0.333496 12.2178 1.45279 13.337 2.8335 13.337H11.2052C12.5859 13.337 13.7052 12.2177 13.7052 10.837V6.63755C13.7052 4.70456 12.1382 3.13756 10.2052 3.13756H1.35948C1.4904 2.44266 2.10056 1.91699 2.8335 1.91699H10.9846C11.2608 1.91699 11.4846 1.69313 11.4846 1.41699C11.4846 1.14085 11.2608 0.916992 10.9846 0.916992H2.8335ZM11.3019 8.55452C11.3019 8.99251 10.9468 9.34757 10.5088 9.34757C10.0708 9.34757 9.71575 8.99251 9.71575 8.55452C9.71575 8.11652 10.0708 7.76146 10.5088 7.76146C10.9468 7.76146 11.3019 8.11652 11.3019 8.55452Z"
      />
    </mask>
    <path
      d="M0.333497 1.41699V1.91675H1.3335V1.41699H0.333497ZM1.35948 3.13756L0.376768 2.9524L0.153471 4.13756H1.35948V3.13756ZM1.3335 3.41699C1.3335 2.58856 2.00507 1.91699 2.8335 1.91699V-0.0830078C0.900499 -0.0830078 -0.666504 1.48399 -0.666504 3.41699H1.3335ZM1.3335 3.63756V3.41699H-0.666504V3.63756H1.3335ZM1.3335 10.837V3.63756H-0.666504V10.837H1.3335ZM2.8335 12.337C2.00507 12.337 1.3335 11.6655 1.3335 10.837H-0.666504C-0.666504 12.77 0.900502 14.337 2.8335 14.337V12.337ZM11.2052 12.337H2.8335V14.337H11.2052V12.337ZM12.7052 10.837C12.7052 11.6655 12.0336 12.337 11.2052 12.337V14.337C13.1382 14.337 14.7052 12.77 14.7052 10.837H12.7052ZM12.7052 6.63755V10.837H14.7052V6.63755H12.7052ZM10.2052 4.13756C11.5859 4.13756 12.7052 5.25684 12.7052 6.63755H14.7052C14.7052 4.15227 12.6905 2.13756 10.2052 2.13756V4.13756ZM1.35948 4.13756H10.2052V2.13756H1.35948V4.13756ZM2.8335 0.916992C1.61084 0.916992 0.595085 1.79368 0.376768 2.9524L2.34219 3.32271C2.38572 3.09165 2.59028 2.91699 2.8335 2.91699V0.916992ZM10.9846 0.916992H2.8335V2.91699H10.9846V0.916992ZM10.4846 1.41699C10.4846 1.14085 10.7085 0.916992 10.9846 0.916992V2.91699C11.8131 2.91699 12.4846 2.24542 12.4846 1.41699H10.4846ZM10.9846 1.91699C10.7085 1.91699 10.4846 1.69313 10.4846 1.41699H12.4846C12.4846 0.588565 11.8131 -0.0830078 10.9846 -0.0830078V1.91699ZM2.8335 1.91699H10.9846V-0.0830078H2.8335V1.91699ZM10.5088 10.3476C11.4991 10.3476 12.3019 9.54479 12.3019 8.55452H10.3019C10.3019 8.44023 10.3945 8.34757 10.5088 8.34757V10.3476ZM8.71575 8.55452C8.71575 9.54479 9.51853 10.3476 10.5088 10.3476V8.34757C10.6231 8.34757 10.7158 8.44023 10.7158 8.55452H8.71575ZM10.5088 6.76146C9.51853 6.76146 8.71575 7.56424 8.71575 8.55452H10.7158C10.7158 8.66881 10.6231 8.76146 10.5088 8.76146V6.76146ZM12.3019 8.55452C12.3019 7.56424 11.4991 6.76146 10.5088 6.76146V8.76146C10.3945 8.76146 10.3019 8.66881 10.3019 8.55452H12.3019Z"
      :fill="clr"
      mask="url(#path-1-inside-1_3956_46147)"
    />
  </svg>
</template>
