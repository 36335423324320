<template>
  <div class="me-6">
    <img src="@/assets/images/BellNotification.svg" @click="value = true" alt="" width="25" height="25"
         style="margin-top: 8px;cursor: pointer">
    <Drawer
        v-model="value"
        :styles="drawerStyles"
        :width="drawerWidth"
        :placement="isLocaleLTR ? 'right' : 'left'"
        :class-name="isLocaleLTR ? 'ltr ltr-filter-drawer header-user-ltr' : 'rtl header-user'"
    >
      <div class="notif-header">
        <p class="font-Regular-18 mt-8" style="color: #b5b5b5">
          {{ $t("common.messages") }}
        </p>
      </div>
      <div class="text-center">
        <img src="@/assets/images/BellNotification2.svg" width="38">
        <p  class="font-Regular-14" style="color: #b5b5b5">{{ $t("common.noMessage") }}</p>
      </div>
    </Drawer>
  </div>
</template>
<script>
export default {
  name: "NotifMenu",
  data() {
    return {
      value: false,
      drawerStyles: {
        height: "98vh",
        width: "335px",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
        left: "0",
        backgroundColor: "#141e2c",
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      drawerWidth: "335px",
    };
  },
}
</script>
<style scoped lang="scss">
.notif-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 98px;
  left: 0;
  top: 0;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 45%;
  border-bottom-left-radius: 45%;
  background-color: rgb(39, 49, 62);
}
</style>